import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { makeStyles } from "@material-ui/styles"
import Navbar from "../components/navbar/navbar";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  container: {
    background: 'linear-gradient(180deg, #F3DCC6 26.04%, #FCCFC9 80.21%)',
    width: '100vw',
    minHeight: '100vh',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  innerContainer: {
    color: '#192C4F',
    paddingTop: 96,
    maxWidth: 400,
    width: '88%',
    display: 'flex',
    flexDirection: 'column'
  },
  title: {
    fontFamily: 'Monofett',
    fontSize: 68,
    color: '#192C4F'
  }
}));


const AboutPage = () => {

const classes = useStyles();

 return (<Layout>
    <SEO
     title="Om Oss - The Måler - Målare i Åre, Järpen, Jämtland."
     description="The Måler - Åre, Järpen, Jämtland. Fredrik Nygren verksam sedan 2016. Färgsätter fasad, tak, väggar, snickerier, luckor, fönster, dörrar och möbler. Erfaren. Rot-avdrag."
    />
    <div className={classes.container}>
      <Navbar page="ABOUT" />
      <div className={classes.innerContainer}>
        <div>
          <Typography variant="body1">
           Jag heter Fredrik och det är jag som är “The Måler”. Som verksam målare sedan 2005 startade jag min egen målerifirma 2020 för att kunna arbeta i nära relation till kund. Under mina år i Åre med omnejd har jag lärt känna hantverkare som tillsammans med mig vill arbeta för att nå det önskade resultat kunden drömmer om. Oavsett om det är den lilla renoveringen eller den stora förvandlingen av ditt semesterboende, fjällstuga, lägenhet, hem så har jag kompetensen och kännedomen av hur vi går till väga.
          </Typography>
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default AboutPage
